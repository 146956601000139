import React from "react";
import Layout from "../layout";
import { graphql } from "gatsby";
import Hero from "../components/ContactPage/Hero/Hero";
import { About } from "../components/ContactPage/About/About";
import Contact from "../components/Contact/Contact";
import Map from "../components/ContactPage/ContactMap/ContactMap";

export const ContactPage = (props) => {
  const { data } = props;
  const contactData = data.allPrismicContactPage.edges[0].node.data;
  return (
    <Layout>
      <Hero data={contactData} />
      <About data={contactData} />
      <Contact map={false} />
      <Map />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  {
    allPrismicContactPage {
      edges {
        node {
          data {
            contact_button_text {
              text
            }
            contact_hero_description {
              text
            }
            contact_hero_img {
              url
            }
            contact_hero_title {
              text
            }
            contact_us_background_img {
              url
            }
            contact_us_description {
              text
              richText
            }
            contact_us_title {
              text
            }
          }
        }
      }
    }
  }
`;
