import React from 'react'
import Map from '../../Map/Map'
import './map.less'

export default () => {
    return (
        <div className='map-container'>
          <Map/>  
        </div>
    )
}
