import React from "react";
import { Container } from "react-bootstrap";

import "./about.less";
import { RichText } from "prismic-reactjs";

export const About = ({ data }) => {
  const contactAboutTitle = data.contact_us_title.text;
  const contactAboutDesc = data.contact_us_description;
  const contactAboutBckImg = data.contact_us_background_img.url;

  return (
    <section className="contact-about-container">
      <article className="contact-about-wrapper">
        <img
          src={contactAboutBckImg}
          alt=""
          className="contact-about-background"
        />
        <Container className="contact-about-deatils">
          <h2>{contactAboutTitle}</h2>
          {RichText.render(contactAboutDesc.richText)}
        </Container>
      </article>
    </section>
  );
};
