import * as React from "react";
import { Container } from "react-bootstrap";
import Modal from "../../Modal/Modal";

import "./hero.less";

export default ({ data }) => {
  const heroImg = data.contact_hero_img.url;
  const heroTitle = data.contact_hero_title.text;
  const heroDesc = data.contact_hero_description.text;
  const btnText = data.contact_button_text.text;

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className="contact-hero">
      <article className="contact-hero-container">
        <img
          src={heroImg}
          alt="Contact Banner"
          className={"image-with-opacity"}
        />
        <Container className="hero-content">
          <h1>{heroTitle}</h1>
          <p>{heroDesc}</p>
          <button
            className="btn consult blue-hover"
            onClick={() => {
              setModalShow(true);
            }}
          >
            {btnText}
          </button>
          {modalShow && (
            <Modal show={modalShow} onHide={() => setModalShow(false)} />
          )}
        </Container>
      </article>
    </section>
  );
};
